import axiosInstance from '../common/instance';

import {
  IdentificationParams,
  SourcePartTimes,
  BankItems,
  PartTimesDataType,
  MergeImageParams,
  PostSourceParttimesDataType,
  GetAgencyParams,
  ValidateFaceIdParams,
  ValidateFaceIdData,
  GetSourceParttimesParams,
  UpdateSourceParttimesParams,
  SourcePartTimesEditData,
  ProjectDataType,
  ProjectParams,
} from './types';

export const checkIdentificationService = async (params: IdentificationParams) => {
  const res = await axiosInstance.post('source-parttimes/check-identification', params);
  return res.data;
};

export const getAgencyService = async (params: GetAgencyParams) => {
  const res = await axiosInstance.get(`agencies/${params.code}`,);
  return res.data;
};

export const getSourceParttimesService = async (params: GetSourceParttimesParams)
  : Promise<SourcePartTimesEditData> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.get(`source-parttimes/show-by-id/${id}`, { params: rest });
  return res.data.data;
};

export const updateSourceParttimesService = async (params: UpdateSourceParttimesParams)
  : Promise<SourcePartTimes> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.put(`source-parttimes/update-by-id/${id}`, rest);
  return res.data;
};

export const postSourceParttimesService = async (params: SourcePartTimes)
  : Promise<PostSourceParttimesDataType> => {
  const bodyForm = new FormData();
  bodyForm.append('name', params.name);
  bodyForm.append('gender', String(params.gender));
  bodyForm.append('birthday', params.birthday);
  bodyForm.append('cellphone', params.cellphone);
  bodyForm.append('cmnd_cccd', params.cmnd_cccd);
  bodyForm.append('name_program', params?.name_program || '');
  bodyForm.append('picture[direct]', params.picture?.direct || '');
  bodyForm.append('picture[left_corner]', params.picture?.left_corner || '');
  bodyForm.append('picture[right_corner]', params.picture?.right_corner || '');
  params.project_ids.forEach((item, idx) => {
    bodyForm.append(`project_ids[${idx}]`, item);
  });
  params.lang_sp.forEach((item, idx) => {
    bodyForm.append(`lang_sp[${idx}]`, item);
  });
  bodyForm.append('agency_code', params.agency_code);
  bodyForm.append('source', String(params.source));
  const res = await axiosInstance.post('source-parttimes', bodyForm);
  return res.data.data;
};

export const getBankListService = async (): Promise<BankItems[]> => {
  const res = await axiosInstance.get('source-parttimes/bank-list');
  return res.data.data;
};

export const getLangListService = async (): Promise<PartTimesDataType[]> => {
  const res = await axiosInstance.get('source-parttimes/languages');
  return res.data.data;
};

export const getRolesListService = async (): Promise<PartTimesDataType[]> => {
  const res = await axiosInstance.get('source-parttimes/roles');
  return res.data.data;
};

export const getChannelsListService = async (): Promise<PartTimesDataType[]> => {
  const res = await axiosInstance.get('source-parttimes/channels');
  return res.data.data;
};

export const mergeImageService = async (params: MergeImageParams) => {
  const res = await axiosInstance.post('source-parttimes/merge-image-staffings', params);
  return res.data;
};

export const validateFaceIdService = async (params: ValidateFaceIdParams)
  : Promise<ValidateFaceIdData> => {
  const res = await axiosInstance.post('source-parttimes/validate-faceid-images', params);
  return res.data.data;
};

export const getProjectService = async (
  params?: ProjectParams,
): Promise<ProjectDataType[]> => {
  const rest = await axiosInstance.get('source-parttimes/projects', { params });
  return rest.data.data;
};
