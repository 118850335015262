import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';

import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import Datepicker from 'components/molecules/Datepicker';
import { OptionType } from 'components/molecules/Pulldown';
import Collapse from 'components/organisms/Collapse';
import { schemaPersonalInformationUpdate } from 'utils/schemas';

type PersonalInformationFormType = {
  name: string;
  gender: number | null;
  birthday: Date | null;
  phone: string;
  // cmnd: string;
};

interface PersonalInformationActionProps {
  provinceList?: OptionType[];
  codeMessage?: string;
  handleChangeCode?: (val: string) => void;
  loadingCode?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export interface PersonalInformationActionRef {
  handleForm: () => Promise<PersonalInformationFormType | undefined>;
  isFormDirty: () => boolean;
  handleOthersForm: () => PersonalInformationFormType | undefined;
  clearOthersForm: (fieldName?: keyof PersonalInformationFormType) => void;
  handleReset: (data?: PersonalInformationFormType) => void;
  handleSetError: (fieldName: keyof PersonalInformationFormType, message: string) => void;
  clearErrors: (fieldName?: keyof PersonalInformationFormType) => void;
}

const PersonalInformation = forwardRef<
  PersonalInformationActionRef, PersonalInformationActionProps>(({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // provinceList,
    // codeMessage,
    // handleChangeCode,
    // loadingCode,
    isDisabled,
    isReadOnly,
  }, ref) => {
    const [open, setOpen] = useState(true);
    const method = useForm<PersonalInformationFormType>({
      mode: 'onSubmit',
      defaultValues: {
        name: '',
        gender: null,
        birthday: null,
        phone: '',
        // cmnd: '',
      },
      resolver: yupResolver(schemaPersonalInformationUpdate),
    });

    const { isDirty } = method.formState;

    useImperativeHandle(ref, () => ({
      handleForm: async () => {
        method.trigger();
        let result: PersonalInformationFormType | undefined;
        await method.handleSubmit((data: PersonalInformationFormType) => {
          result = data;
        })();
        return result;
      },
      handleSetError: (
        fieldName: keyof PersonalInformationFormType,
        message: string,
      ) => method.setError(`${fieldName}`, { message }),
      isFormDirty: () => isDirty,
      handleOthersForm: () => method.getValues(),
      clearOthersForm: (
        fieldName?: keyof PersonalInformationFormType
      ) => {
        if (fieldName) {
          method.resetField(fieldName);
        } else {
          method.reset();
        }
      },
      handleReset: (data?: PersonalInformationFormType) => method.reset(data),
      clearErrors: (fieldName?: keyof PersonalInformationFormType) => method.clearErrors(fieldName),
    }));

    return (
      <Collapse
        hasBorder
        title="Thông tin cá nhân"
        active={open}
        handleOpen={() => setOpen(!open)}
      >
        <FormProvider {...method}>
          <form noValidate>

            <Controller
              name="name"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  error={fieldState?.error?.message}
                  subLabel="Họ và tên:"
                  placeholder="Nhập họ và tên"
                  disabled={isDisabled}
                  isReadOnly={isReadOnly}
                  horizontal={isReadOnly}
                />
              )}
            />

            <div className="u-mt-12">
              <Controller
                name="gender"
                render={({ field, fieldState }) => (
                  <>
                    <div className="personalInformationUpdate_gender">
                      <div>
                        <Text type="span" modifiers={['14x20', 'gunmetal', '500']}>Giới tính:</Text>
                      </div>
                      <div className="u-ml-10">
                        {isReadOnly
                          ? (
                            <Text modifiers={['gunmetal', '14x20']}>
                              {field.value === 0 ? 'Nam' : 'Nữ'}
                            </Text>
                          ) : (
                            <>
                              <Radio disabled={isDisabled} onChange={() => field.onChange(0)} checked={field.value === 0} label="Nam" />
                              <Radio disabled={isDisabled} onChange={() => field.onChange(1)} checked={field.value === 1} label="Nữ" />
                            </>
                          )}
                      </div>
                    </div>
                    {fieldState?.error?.message && (
                      <div className="u-mt-8">
                        <Text
                          modifiers={['14x20', 'redOrange', '400']}
                        >
                          {fieldState?.error?.message}
                        </Text>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="u-mt-12">
              <Controller
                name="birthday"
                render={({ field, fieldState }) => (
                  <Datepicker
                    placeholderText="DD/MM/YYYY"
                    onChange={field.onChange}
                    selected={field.value}
                    id="datepicker-birthday"
                    label="Ngày sinh:"
                    dateFormat="dd/MM/yyyy"
                    error={fieldState?.error?.message}
                    disabled={isDisabled}
                    isReadOnly={isReadOnly}
                  />
                )}
              />
            </div>
            <div className="u-mt-12">
              <Controller
                name="phone"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    error={fieldState?.error?.message}
                    subLabel="Số điện thoại:"
                    placeholder="Nhập số điện thoại"
                    horizontal
                    type="tel"
                    disabled={isDisabled}
                    isReadOnly={isReadOnly}
                  />
                )}
              />
            </div>

            {/* <div className="u-mt-12">
              <Controller
                name="cmnd"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    error={error?.message}
                    subLabel="Số CCCD:"
                    placeholder="Nhập CMND/CCCD"
                    horizontal
                    type="tel"
                    onChange={(e) => {
                      field.onChange(e);
                      if (handleChangeCode) handleChangeCode(e.target.value);
                    }}
                    message={!error?.message ? codeMessage : ''}
                    loading={loadingCode}
                    disabled={isDisabled}
                    isReadOnly={isReadOnly}
                  />
                )}
              />
            </div> */}
          </form>
        </FormProvider>
      </Collapse>
    );
  });

export default PersonalInformation;
