import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Images, { ImagesActionProps } from './Container/Images';
import Job, { JobActionProps } from './Container/Job';
import PersonalInformation, { PersonalInformationActionRef } from './Container/PersonalInformation';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import NotifyModal from 'components/templates/NotifyModal';
import useDebounce from 'hooks/useDebounce';
import useInitializeRender from 'hooks/useInitializeRender';
import {
  getProvincesListService,
} from 'services/locations';
import {
  getAgencyService,
  mergeImageService,
  postSourceParttimesService,
  checkIdentificationService,
} from 'services/sourceParttimes';
import { MergeImageParams, SourcePartTimes } from 'services/sourceParttimes/types';
import { IMAGE_SIZE_1MB, URL_CONST } from 'utils/constants';
import { compressImage, formatDateDDMMYYY } from 'utils/functions';

const Home: React.FC = () => {
  //* Hooks
  const { slug: agencyCode } = useParams<{ slug: string }>();

  useInitializeRender();

  //* States
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    message: '',
  });
  const [imgUuidList, setImgUuidList] = useState<string[]>([]);
  const [submittingData, setSubmittingData] = useState<SourcePartTimes>();
  const [isNotFound, setIsNotFound] = useState(false);
  const [code, setCode] = useState('');
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [codeMessage, setCodeMessage] = useState<string | undefined>(undefined);

  const personalInformationRef = useRef<PersonalInformationActionRef>(null);
  const jobRef = useRef<JobActionProps>(null);
  const imagesRef = useRef<ImagesActionProps>(null);

  //* React-query
  const {
    mutate: mutateCheckCode,
    isLoading: loadingCode
  } = useMutation(checkIdentificationService, {
    onSuccess() {
      setCodeMessage('Số CCCD / CMND có thể sử dụng.');
      personalInformationRef.current?.clearErrors('cmnd');
      setIsErrorCode(false);
    },
    onError: () => {
      setIsErrorCode(true);
      setCodeMessage(undefined);
      personalInformationRef.current?.handleSetError('cmnd', 'Số CCCD / CMND đã được đăng kí!');
    }
  });

  const { isLoading: agenciesLoading } = useQuery(
    ['project', 'agencyCode', agencyCode],
    () => getAgencyService({
      code: agencyCode || '',
    }),
    {
      enabled: !!agencyCode,
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        } else {
          setErrorModal({
            open: true,
            message: 'Đã có lỗi xảy ra! Vui lòng thử lại!',
          });
        }
      }
    }
  );

  const { data: provinceRes } = useQuery(
    ['getProvinceList'],
    () => getProvincesListService(),
    {
      enabled: !!agencyCode && !agenciesLoading && !isNotFound,
    }
  );

  const { mutate: mergeMutate } = useMutation(
    ['merge-image-source-parttimes'],
    async (params: MergeImageParams) => mergeImageService(params),
    {
      onSettled: () => {
        setImgUuidList([]);
      },
      onSuccess: () => {
        setIsLoading(false);
        setIsOpen(true);
        jobRef.current?.clearOthersForm();
        personalInformationRef.current?.clearOthersForm();
        imagesRef.current?.clearOthersForm();
        setImgUuidList([]);
        setSubmittingData(undefined);
        setCodeMessage(undefined);
        setCode('');
      },
      onError: () => {
        setIsLoading(false);
        setErrorModal({
          open: true,
          message: 'Gửi ảnh thất bại! Vui lòng thử lại!',
        });
      },
    }
  );

  const { mutate: postMutate } = useMutation(
    ['post-source-parttimes'],
    async (params: SourcePartTimes) => postSourceParttimesService(params),
    {
      onSuccess: (res) => {
        mergeMutate({
          source_id: res.id,
          data: {
            profile_picture: imgUuidList
          }
        });
      },
      onError: (errors: ErrorResponse[]) => {
        setImgUuidList([]);
        setIsLoading(false);
        if (Array.isArray(errors) && errors.length > 0) {
          if (errors[0].field === 'cmnd_cccd') {
            personalInformationRef?.current?.handleSetError('cmnd', 'Số CMND/CCCD đã được đăng ký!');
          }
        }
        setErrorModal({
          open: true,
          message: 'Đăng ký chưa thành công, vui lòng ấn đăng ký lại',
        });
      },
    }
  );

  const provinceList = useMemo(() => {
    if (provinceRes && provinceRes?.length > 0) {
      return provinceRes.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.name,
      }));
    }
    return [];
  }, [provinceRes]);

  //* Fine-uploader
  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
      uuidName: 'file_uuid',
      inputName: 'file',
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: IMAGE_SIZE_1MB, // 1MB,
      paramNames: {
        partIndex: 'file_index',
      }
    },
    callbacks: {
      onUpload(id: number) {
        const uuid = uploader.getUuid(id) as string;
        setImgUuidList((prev) => [...prev, uuid]);
      },
      onComplete(id: number, name: string, res: {
        success: boolean;
        data?: Object;
      }) {
        const uuid = uploader.getUuid(id) as string;
        if (!res.success) {
          // const errors = JSON.parse(xhr.response).errors as ErrorResponse[];
          setErrorModal({
            open: true,
            message: 'Upload ảnh thất bại! Vui lòng thử lại!'
          });
          setImgUuidList((prev) => ([...prev.filter((ele) => ele !== uuid)]));
        }
      },
    }
  });

  const handleSubmit = async () => {
    const valueJob = await jobRef.current?.handleForm();
    const valuePersonalInformation = await personalInformationRef.current?.handleForm();
    const valueImages = await imagesRef.current?.handleForm();

    if (valueJob
      && valuePersonalInformation
      && valueImages
      && codeMessage) {
      setIsLoading(true);

      const compressedThumbnail = valueImages?.listThumbnail ? await Promise.all(
        valueImages.listThumbnail.map(async (ele, index) => {
          const {
            returnedFile,
          } = await compressImage(`thumbnail-${index.toString()}`, ele, 'image/jpeg', 0.7);
          return returnedFile;
        })
      ) : undefined;

      // MaHVN == cmnd_cccd
      // Nhãn hàng thực hiện == name_program
      setSubmittingData({
        name: valuePersonalInformation?.name || '',
        gender: valuePersonalInformation?.gender || 0,
        birthday: formatDateDDMMYYY(valuePersonalInformation?.birthday || undefined),
        cellphone: valuePersonalInformation?.phone || '',
        cmnd_cccd: valuePersonalInformation?.cmnd || '',
        lang_sp: valueJob?.language || [],
        name_program: valueJob?.prjName?.value === 'other'
          ? valueJob?.otherName
          : '',
        project_ids: valueJob?.prjName?.value === 'other' ? [] : [String(valueJob?.prjName?.id)],
        picture: {
          direct: valueImages?.faceIDFront,
          left_corner: valueImages?.faceIDLeft,
          right_corner: valueImages?.faceIDRight,
          profile_picture: compressedThumbnail,
        },
        agency_code: agencyCode || '',
        source: 2
      });

      uploader.addFiles([...(compressedThumbnail || [])]);
      uploader.uploadStoredFiles();
    }
  };

  useDebounce(() => code
    && (code.length >= 9 && code.length <= 12)
    && mutateCheckCode({ cmnd_cccd: code }), 1000, [code]);

  useEffect(() => {
    if (!!imgUuidList.length && submittingData
      && imgUuidList.length === ((submittingData.picture?.profile_picture?.length || 0))) {
      const data = { ...submittingData };
      postMutate({ ...data });
    }
  }, [imgUuidList, submittingData, postMutate]);

  if (!agencyCode) {
    return (
      <div className="invalid">
        <Heading modifiers={['32x42', '700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (agenciesLoading) {
    return (
      <Loading isShow />
    );
  }

  if (isNotFound) {
    return (
      <div className="invalid">
        <Heading modifiers={['32x42', '700', 'center']}>
          Không tìm thấy Agency mã
          {' '}
          {agencyCode || ''}
          , xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <div className="container">
      <PersonalInformation
        ref={personalInformationRef}
        provinceList={provinceList}
        handleChangeCode={setCode}
        loadingCode={loadingCode}
        codeMessage={codeMessage}
      />
      <div className="u-mt-24">
        <Images ref={imagesRef} />
      </div>
      <div className="u-mt-24">
        <Job ref={jobRef} />
      </div>
      <div className="u-mt-24">
        <Button
          modifiers={['lg', 'primary']}
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isErrorCode || isLoading}
        >
          <Text modifiers={['white', '14x20', '600']}>
            Hoàn thành
          </Text>
        </Button>
      </div>
      <NotifyModal
        isOpen={isOpen}
        title="Đã đăng ký thành công!"
        smallTitle
        submitText="Đóng"
        handleSubmit={() => setIsOpen(false)}
      />
      <NotifyModal
        isError
        isOpen={errorModal.open}
        title="Thất bại"
        desc={errorModal.message || 'Đã có lỗi xảy ra. Vui lòng thử lại!'}
        submitText="Đóng"
        handleSubmit={() => setErrorModal({
          open: false,
          message: '',
        })}
      />
    </div>
  );
};

export default Home;
