import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import FACE_AHEAD from 'assets/images/direct.png';
import FACE_LEFT from 'assets/images/left.png';
import FACE_RIGHT from 'assets/images/right.png';
import Icon, { IconProps } from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Collapse from 'components/organisms/Collapse';
import CameraCapture, { CameraCaptureRef } from 'components/templates/CameraCapture';
import NotifyModal from 'components/templates/NotifyModal';
import { validateFaceIdService } from 'services/sourceParttimes';
import { ValidateFaceIdError, ValidateFaceIdParams } from 'services/sourceParttimes/types';
import { IMAGE_SIZE_1MB, URL_CONST } from 'utils/constants';
import { SUPPORTED_FORMATS } from 'utils/schemas';

interface SkeletonProps {
  onClick?: () => void;
  icon: IconProps;
  text?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  icon,
  onClick,
  text
}) => (
  <div className="skeleton" onClick={onClick}>
    <div>
      <Icon {...icon} />
      {text
        && (
          <Text modifiers={['14x20', '500', 'mountainMeadow']}>
            {text}
          </Text>
        )}
    </div>
  </div>
);

interface FaceProps {
  title?: string;
  active?: boolean;
  imageSrc: string;
  note?: string;
  onClick?: () => void;
}

const Face: React.FC<FaceProps> = ({
  title,
  active,
  imageSrc,
  note,
  onClick
}) => (
  <div className="images_face">
    <Text modifiers={['14x20', '500', 'eerieBlack', 'center']}>
      {title}
    </Text>
    <div className="images_face_row">
      <div className="images_face_col">
        {active ? <Image imgSrc={imageSrc} ratio="1x1" alt="thumbnail" /> : (
          <div className="images_face_wrapper">
            <Image imgSrc={imageSrc} ratio="1x1" alt="thumbnail" />
            {note && (
              <div className="images_face_note">
                <Text modifiers={['14x20', 'eerieBlack', '400', 'center']}>
                  {note}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="images_face_col cursor-pointer">
        <Skeleton
          onClick={onClick}
          icon={{
            iconName: 'camera',
            size: '28'
          }}
          text={active ? 'Chụp lại' : 'Chụp ảnh'}
        />
      </div>
    </div>
  </div>
);

type ImagesFormType = {
  faceIDLeft?: string;
  faceIDRight?: string;
  faceIDFront?: string;
  listThumbnail?: Array<File>;
};

type ImagesSrcFormType = {
  faceIdLeftSrc?: string;
  faceIdRightSrc?: string;
  faceIdFrontSrc?: string;
  listImages?: string[];
};

export interface ImagesActionProps {
  handleForm: () => Promise<ImagesFormType | undefined>;
  isFormDirty: () => boolean;
  handleOthersForm: () => ImagesFormType | undefined;
  clearOthersForm: (fieldName?: keyof ImagesFormType) => void;
  handleReset: (data: ImagesSrcFormType) => void,
}

interface ImagesProps {
  isDisabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Images = forwardRef<ImagesActionProps, ImagesProps>(({
  isDisabled,
}, ref) => {
  const [open, setOpen] = useState(true);
  const [openCapture, setOpenCapture] = useState<number>(-1);
  const [imgSrcLoad, setImgSrcLoad] = useState<ImagesSrcFormType>({
    faceIdLeftSrc: '',
    faceIdRightSrc: '',
    faceIdFrontSrc: '',
    listImages: [],
  });
  const [uploadUuid, setUploadUuid] = useState('');
  const [checkLoading, setCheckLoading] = useState(false);
  const [errorModal, setErrorModal] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const inputListFileRef = useRef<HTMLInputElement>(null);
  const carmeraRef = useRef<CameraCaptureRef>(null);
  const faceCheckTotalRef = useRef<number>(0);

  const method = useForm<ImagesFormType>({
    mode: 'onChange',
    defaultValues: {
      listThumbnail: undefined,
    },
  });

  const faceIDLeft = method.watch('faceIDLeft');
  const faceIDFront = method.watch('faceIDFront');
  const faceIDRight = method.watch('faceIDRight');

  const { isDirty } = method.formState;

  useImperativeHandle(ref, () => ({
    handleForm: async () => {
      method.trigger();
      let result: ImagesFormType | undefined;
      await method.handleSubmit((data: ImagesFormType) => {
        result = data;
      })();
      return result;
    },
    isFormDirty: () => isDirty,
    handleOthersForm: () => method.getValues(),
    handleReset: (data) => {
      setImgSrcLoad(data);
      method.reset({
        faceIDLeft: data.faceIdLeftSrc,
        faceIDFront: data.faceIdFrontSrc,
        faceIDRight: data.faceIdRightSrc,
      });
    },
    clearOthersForm: (
      fieldName?: keyof ImagesFormType
    ) => {
      if (fieldName) {
        method.resetField(fieldName);
      } else {
        method.reset();
      }
    }
  }));

  //* Fine-uploader
  const uploader = useMemo(
    () => new FineUploaderBasic({
      autoUpload: false,
      request: {
        endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
        uuidName: 'file_uuid',
        inputName: 'file',
      },
      chunking: {
        enabled: true,
        mandatory: true,
        partSize: IMAGE_SIZE_1MB, // 1MB,
        paramNames: {
          partIndex: 'file_index',
        }
      },
      callbacks: {
        onUpload() {
          faceCheckTotalRef.current += 1;
        },
        onComplete(id: number, name: string, res: {
          success: boolean;
          data?: Object;
        }) {
          const uuid = uploader.getUuid(id) as string;
          if (!res.success) {
            setCheckLoading(false);
            faceCheckTotalRef.current = 0;
            // const errors = JSON.parse(xhr.response).errors as ErrorResponse[];
            setErrorModal({
              open: true,
              message: 'Upload ảnh thất bại! Vui lòng thử lại!'
            });
          } else {
            setUploadUuid(uuid);
          }
        },
      }
    }),
    []
  );

  const showToast = () => {
    toast.success(<Text modifiers={['16x24', '600', 'eerieBlack']}>Chụp ảnh thành công!</Text>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  //* React-query
  const { mutate: validateMutate } = useMutation(
    ['validate-faceid-source-parttimes-update'],
    async (params: ValidateFaceIdParams) => validateFaceIdService(params),
    {
      onSettled: () => {
        faceCheckTotalRef.current = 0;
        setCheckLoading(false);
      },
      onSuccess: (data) => {
        switch (data.step) {
          case 'left_corner': {
            method.setValue('faceIDLeft', data.path);
            setImgSrcLoad((prev) => ({ ...prev, faceIdLeftSrc: data.path }));
            break;
          }
          case 'direct': {
            method.setValue('faceIDFront', data.path);
            setImgSrcLoad((prev) => ({ ...prev, faceIdFrontSrc: data.path }));
            break;
          }
          case 'right_corner': {
            method.setValue('faceIDRight', data.path);
            setImgSrcLoad((prev) => ({ ...prev, faceIdRightSrc: data.path }));
            break;
          }
          default: setOpenCapture(-1);
        }
        showToast();
      },
      onError: (err) => {
        const error = err as ValidateFaceIdError;
        if (error?.leftCorner || error?.rightCorner || error?.direct) {
          setErrorModal({
            open: true,
            message: 'Vui lòng di chuyển khuôn mặt vào giữa khung hình và không xoay mặt quá 3/4'
          });
        }
        setUploadUuid('');
      },
    }
  );

  //* Functions
  const handleFinishCapture = (photos: File[]) => {
    if (photos.length) {
      setOpenCapture(-1);
    }
  };

  const handleCheckFaceId = useCallback((photos: File[]) => {
    if (photos && photos.length > 0) {
      setCheckLoading(true);
      uploader.addFiles([photos[0]]);
      uploader.uploadStoredFiles();
    }
  }, [uploader]);

  //* Effects
  useEffect(() => {
    if (uploadUuid && faceCheckTotalRef.current) {
      let currStep = '';
      switch (openCapture) {
        case 0: currStep = 'left_corner'; break;
        case 1: currStep = 'direct'; break;
        case 2: currStep = 'right_corner'; break;
        default: setOpenCapture(-1);
      }
      if (currStep) {
        validateMutate({
          file_uuid: uploadUuid,
          step: currStep
        });
      }
    }
  }, [openCapture, validateMutate, uploadUuid]);

  return (
    <>
      <Collapse
        hasBorder
        title="Hình ảnh"
        active={open}
        handleOpen={() => setOpen(!open)}
      >
        <FormProvider {...method}>
          <form noValidate>

            <Text modifiers={['14x20', '500', 'gunmetal']}>
              Hình Face ID
              <span className="a-input_subLabel-required">*</span>
            </Text>

            <div className="a-input_messageError">
              <Text modifiers={['14x20', 'redOrange', '600']}>
                *Vui lòng chụp hình khuôn mặt chính giữa khung hình và chiếm 3/4
                khung hình trong điều kiện ánh sáng tốt, tóc tai gọn gàng.
              </Text>
              <Text modifiers={['14x20', 'redOrange', '600']}>
                *Những hình khuôn mặt này dùng để nhận diện
                khuôn mặt khi checkin ca làm việc trên App.
              </Text>
            </div>

            <div className="u-mt-12" />

            <Face
              active={!!faceIDLeft}
              imageSrc={(faceIDLeft)
                || imgSrcLoad.faceIdLeftSrc
                || FACE_LEFT}
              onClick={() => !isDisabled && setOpenCapture(0)}
              title="Chụp ảnh mặt trái"
              note="3/4 view"
            />

            <div className="divider" />

            <Face
              active={!!faceIDFront}
              imageSrc={(faceIDFront)
                || imgSrcLoad.faceIdFrontSrc
                || FACE_AHEAD}
              title="Chụp ảnh mặt trước"
              onClick={() => !isDisabled && setOpenCapture(1)}
              note="Front"
            />

            <div className="divider" />

            <Face
              active={!!faceIDRight}
              imageSrc={(faceIDRight)
                || imgSrcLoad.faceIdRightSrc
                || FACE_RIGHT}
              title="Chụp ảnh mặt phải"
              onClick={() => !isDisabled && setOpenCapture(2)}
              note="3/4 view"
            />

            <div className="u-mt-24">
              <Text modifiers={['14x20', '500', 'gunmetal']}>
                Hình cá nhân
                <span className="a-input_subLabel-required">*</span>
              </Text>

              <Controller
                name="listThumbnail"
                // rules={{
                //   validate: (value) =>
                // (Array.isArray(value) && value.length > 3) || 'Tối thiểu từ 3 hình ảnh'
                // }}
                render={({ field, fieldState }) => (
                  <>

                    <div className="images_thumbnail">
                      <div className="images_thumbnail_row">
                        {imgSrcLoad.listImages
                          && [...imgSrcLoad.listImages].map((src: string, i: number) => (
                            <div
                              key={`thumbnail-${i.toString()}`}
                              className="images_thumbnail_col"
                            >
                              <Image
                                imgSrc={src}
                                ratio="1x1"
                                alt="thumbnail"
                              />
                            </div>
                          ))}
                        {field.value && [...field.value].map((x: File, i: number) => (
                          <div
                            key={`thumbnail-${i.toString()}`}
                            className="images_thumbnail_col"
                          >
                            <Image
                              imgSrc={URL.createObjectURL(x)}
                              ratio="1x1"
                              alt="thumbnail"
                              handleClose={() => {
                                const remain = [...field.value].filter((_, idx) => idx !== i);
                                field.onChange(remain.length === 0 ? undefined : remain);
                              }}
                            />
                          </div>
                        ))}
                        <div className="images_thumbnail_col cursor-pointer">
                          <input
                            onBlur={field.onBlur}
                            onChange={(e) => {
                              if (e.target.files) {
                                const listAllow = [...(e.target.files as any)].filter(
                                  (x: File) => SUPPORTED_FORMATS.includes(x.type)
                                );
                                const newsList = [
                                  ...(field.value || []),
                                  ...listAllow
                                ];
                                field.onChange(newsList);
                                // eslint-disable-next-line no-param-reassign
                                e.target.value = '';
                              }
                            }}
                            hidden
                            ref={inputListFileRef}
                            multiple
                            type="file"
                            disabled={isDisabled}
                          />
                          <Skeleton
                            onClick={() => {
                              if (inputListFileRef.current && !isDisabled) {
                                inputListFileRef.current.click();
                              }
                            }}
                            icon={{
                              iconName: 'add',
                              size: '20'
                            }}
                          />
                        </div>

                      </div>
                    </div>

                    <div className="u-mt-8">
                      <Text modifiers={['14x20', '500', 'stormcloud']}>
                        Tải lên tối thiểu từ 3 hình ảnh
                      </Text>
                    </div>
                    {fieldState?.error?.message && (
                      <div className="u-mt-8">
                        <Text
                          modifiers={['14x20', 'redOrange', '400']}
                        >
                          {fieldState?.error?.message}
                        </Text>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </Collapse>
      {
        openCapture !== -1 && (
          <CameraCapture
            ref={carmeraRef}
            isSingle
            loading={checkLoading}
            handleBack={() => setOpenCapture(-1)}
            handleFinish={handleFinishCapture}
            handleCheckFaceId={handleCheckFaceId}
            // eslint-disable-next-line no-nested-ternary
            name={`faceID${openCapture === 0 ? 'left' : openCapture === 1 ? 'front' : 'right'}`}
          />
        )
      }
      <NotifyModal
        isError
        isOpen={errorModal.open}
        title="Thất bại"
        desc={errorModal.message || 'Đã có lỗi xảy ra. Vui lòng thử lại!'}
        submitText="Đóng"
        handleSubmit={() => {
          setErrorModal({ open: false });
          carmeraRef.current?.handleReCapture();
        }}
      />
    </>
  );
});

export default Images;
