/* eslint-disable max-len */
import * as yup from 'yup';

export const phoneRegExp = /(0[3|5|7|8|9])+([0-9]{8})\b/;
export const numberOnlyReg = /^\d+$/;

export const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const schemaPersonalInformation = yup.object({
  name: yup.string().required('Họ và tên là bắt buộc'),
  gender: yup.string().required('Giới tính là bắt buộc'),
  birthday: yup.date().nullable().required('Ngày sinh là bắt buộc'),
  phone: yup.string().required('Số điện thoại là bắt buộc').matches(phoneRegExp, 'Không đúng định dạng'),
  cmnd: yup.string()
    .matches(numberOnlyReg, 'Số CCCD /CMND chưa đúng định dạng số')
    .required('Số CCCD /CMND là bắt buộc')
    .min(9, 'Số CCCD / CMND tối thiểu là 9 số')
    .max(12, 'Số CCCD / CMND tối da là 12 số'),
});

export const schemaPersonalInformationUpdate = yup.object({
  name: yup.string(),
  gender: yup.string().nullable(),
  birthday: yup.date().nullable(),
  phone: yup.string().notRequired().test('phone', 'Không đúng định dạng', (value) => {
    if (value) {
      const schema = yup.string().matches(phoneRegExp, 'Không đúng định dạng');
      return schema.isValidSync(value);
    }
    return true;
  }),
  // cmnd: yup.string().notRequired().test('cmnd', 'Số CCCD /CMND chưa đúng định dạng số', (value) => {
  //   if (value) {
  //     const schema = yup.string().matches(numberOnlyReg, 'Số CCCD /CMND chưa đúng định dạng số')
  //       .min(9, 'Số CCCD / CMND tối thiểu là 9 số')
  //       .max(12, 'Số CCCD / CMND tối da là 12 số');
  //     return schema.isValidSync(value);
  //   }
  //   return true;
  // }),
});

export const schemaPersonalInformationApproved = yup.object({
  name: yup.string(),
  gender: yup.string(),
  birthday: yup.date().nullable(),
  phone: yup.string(),
  cmnd: yup.string(),
});

export const schemaJob = yup.object({
  // location: yup.array().required('Vị trí là bắt buộc').min(1, 'Vị trí là bắt buộc'),
  confirm: yup.bool().oneOf([true], 'Thông tin này là bắt buộc'),
  prjName: yup.object().nullable().required('Tên dự án là bắt buộc'),
});

export const schemaJobUpdate = yup.object({
  prjName: yup.array().required('Tên dự án là bắt buộc').min(1, 'Chọn tối thiểu 1 dự án'),
  confirm: yup.bool().oneOf([true], 'Thông tin này là bắt buộc'),
});

export const schemaBankInformation = yup.object({
  accountNumber: yup.string().required('Số tài khoản là bắt buộc'),
  beneficiaryAccountName: yup.string().required('Tên tài khoản thụ hưởng là bắt buộc'),
  province: yup.object().nullable().required('Tỉnh/Thành phố là bắt buộc'),
  bank: yup.object().nullable().required('Ngân hàng là bắt buộc'),
  branch: yup.string().required('Chi nhánh là bắt buộc'),
});

export const schemaImages = yup.object().shape({
  faceIDLeft: yup.string().required('Hình chụp là bắt buộc !'),
  faceIDFront: yup.string().required('Hình chụp là bắt buộc !'),
  faceIDRight: yup.string().required('Hình chụp là bắt buộc !'),
  listThumbnail: yup
    .array()
    .required('Hình cá nhân là bắt buộc')
    .min(1, 'Tối thiểu từ 1 hình ảnh'),
});

export const checkIdentifySchema = yup.object().shape({
  load_field: yup.string().required('Thông tin này là bắt buộc'),
  birthday: yup.date().nullable().required('Ngày sinh là bắt buộc'),
  phone: yup.string().required('Số điện thoại là bắt buộc').matches(phoneRegExp, 'Không đúng định dạng'),
});
