import hvnLogo from 'assets/images/hvn_logo.png';

export const policiesData = `
<img src=${hvnLogo} alt="hvnLogo" width="180" height="75" />
</br>
  <p class="c11 c14"><span class="c19"></span></p>
  <p class="c11"><span class="c19">TH&Ocirc;NG B&Aacute;O &#272;I&#7872;U KHO&#7842;N B&#7842;O V&#7878; D&#7918;
      LI&#7878;U C&Aacute; NH&Acirc;N C&#7910;A NH&Acirc;N VI&Ecirc;N</span></p>
  <p class="c11"><span class="c19">LI&Ecirc;N QUAN &#272;&#7870;N &#7912;NG D&#7908;NG ON TRADE</span></p>
  <p class="c11"><span class="c19">(SAU &#272;&Acirc;Y VI&#7870;T T&#7854;T L&Agrave; &ldquo;TH&Ocirc;NG
      B&Aacute;O&rdquo;)</span></p>
  <p class="c1 c14"><span class="c18 c20"></span></p>
  <p class="c11"><span class="c18 c17 c22">NOTICE ON TERMS OF EMPLOYEE DATA PROTECTION</span></p>
  <p class="c11"><span class="c22 c18 c17">RELATED TO THE ON TRADE APPLICATION</span></p>
  <p class="c11"><span class="c22 c18 c17">(HEREINAFTER REFERRED AS &ldquo;NOTICE&rdquo;)</span></p>
  <p class="c11"><span class="c27">-----------------------------</span></p>
  <p class="c1"><span class="c2">HEINEKEN Vietnam Brewery Limited Company and its affiliates, branches &amp;
      representative offices (hereinafter referred to as &quot;HVN&quot; or &quot;We&quot; or &quot;Us&quot; or
      &ldquo;the Company&rdquo;) are committed to protecting the privacy of our employees, including employees
      contracted to third-party service providers. </span></p>
  <p class="c1"><span class="c2">We respect your privacy, and we are committed to keeping your Personal Data secure and
      managing it in accordance with our legal responsibilities under applicable laws and regulations on personal data
      protection.</span></p>
  <p class="c1"><span class="c2">In this Notice, we describe how we process and protect your personal data related to
      the ON TRADE application (hereinafter referred to as &ldquo;Application&rdquo;). We are the Controller of your
      personal data.</span></p>
  <p class="c1"><span class="c4">C&ocirc;ng ty TNHH Nh&agrave; m&aacute;y Bia HEINEKEN Vi&#7879;t Nam (Sau
      &#273;&acirc;y vi&#7871;t t&#7855;t l&agrave; &quot;HVN&quot; ho&#7863;c &quot;Ch&uacute;ng t&ocirc;i&quot;
      ho&#7863;c &ldquo;C&ocirc;ng ty&rdquo;)&nbsp;v&agrave; c&aacute;c c&ocirc;ng ty li&ecirc;n k&#7871;t, c&aacute;c
      chi nh&aacute;nh v&agrave; v&#259;n ph&ograve;ng &#273;&#7841;i di&#7879;n c&#7911;a HVN cam k&#7871;t b&#7843;o
      v&#7879; quy&#7873;n ri&ecirc;ng t&#432; c&#7911;a nh&acirc;n vi&ecirc;n, bao g&#7891;m nh&acirc;n vi&ecirc;n
      k&yacute; h&#7907;p &#273;&#7891;ng v&#7899;i c&aacute;c b&ecirc;n cung c&#7845;p d&#7883;ch v&#7909; th&#7913;
      ba. </span></p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i t&ocirc;n tr&#7885;ng quy&#7873;n ri&ecirc;ng t&#432; c&#7911;a
      b&#7841;n v&agrave; cam k&#7871;t b&#7843;o m&#7853;t D&#7919; li&#7879;u C&aacute; nh&acirc;n c&#7911;a b&#7841;n
      v&agrave; qu&#7843;n l&yacute; d&#7919; li&#7879;u b&#7857;ng tr&aacute;ch nhi&#7879;m ph&aacute;p l&yacute;
      c&#7911;a Ch&uacute;ng t&ocirc;i theo quy &#273;&#7883;nh hi&#7879;n h&agrave;nh v&#7873; b&#7843;o v&#7879;
      d&#7919; li&#7879;u c&aacute; nh&acirc;n.</span></p>
  <p class="c1"><span class="c4">Trong Th&ocirc;ng b&aacute;o n&agrave;y, Ch&uacute;ng t&ocirc;i m&ocirc; t&#7843;
      c&aacute;ch Ch&uacute;ng t&ocirc;i x&#7917; l&yacute; v&agrave; b&#7843;o v&#7879; d&#7919; li&#7879;u c&aacute;
      nh&acirc;n c&#7911;a b&#7841;n li&ecirc;n quan &#273;&#7871;n &#7913;ng d&#7909;ng ON TRADE, Ch&uacute;ng
      t&ocirc;i l&agrave; Ng&#432;&#7901;i ki&#7875;m so&aacute;t d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a
      b&#7841;n.</span></p>
  <ol class="c0 lst-kix_list_1-0 start" start="1">
    <li class="c1 c24 li-bullet-0"><span class="c6">For which purposes do we use your personal data/</span><span
        class="c15">&nbsp;</span><span class="c5">Ch&uacute;ng t&ocirc;i s&#7917; d&#7909;ng d&#7919; li&#7879;u
        c&aacute; nh&acirc;n c&#7911;a b&#7841;n cho m&#7909;c &#273;&iacute;ch g&igrave;</span></li>
  </ol>
  <p class="c1"><span class="c2">We use your personal data for the following purposes:</span></p>
  <ul class="c0 lst-kix_list_3-0 start">
    <li class="c10 li-bullet-0"><span class="c2">Registering and creating your account on the application. </span></li>
    <li class="c10 li-bullet-0"><span class="c2">Processing information and work-related images during your shift, such
        as check-in/check-out information on the system, working hours, locating your work position during the shift,
        daily sales productivity, gift-giving activities, sampling activities, and interacting with customers (playing
        games, inviting products, introducing programs, etc.).</span></li>
    <li class="c1 c25 li-bullet-0"><span class="c2">We need the above information to ensure quality of each shift and
        manage performance of the program.</span></li>
  </ul>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i s&#7917; d&#7909;ng d&#7919; li&#7879;u c&aacute; nh&acirc;n
      c&#7911;a b&#7841;n nh&#7857;m c&aacute;c m&#7909;c &#273;&iacute;ch sau &#273;&acirc;y:</span></p>
  <ul class="c0 lst-kix_list_3-0">
    <li class="c10 li-bullet-0"><span class="c4">&#272;&#259;ng k&yacute; v&agrave; t&#7841;o t&agrave;i kho&#7843;n
        c&#7911;a b&#7841;n tr&ecirc;n &#7912;ng d&#7909;ng.</span></li>
    <li class="c10 li-bullet-0"><span class="c4">X&#7917; l&yacute; th&ocirc;ng tin v&agrave; h&igrave;nh &#7843;nh
        l&agrave;m vi&#7879;c trong ca c&#7911;a b&#7841;n nh&#432; th&ocirc;ng tin checkin/checkout tr&ecirc;n h&#7879;
        th&#7889;ng, gi&#7901; l&agrave;m vi&#7879;c, &#273;&#7883;nh v&#7883; v&#7883; tr&iacute; l&agrave;m vi&#7879;c
        trong ca, s&#7843;n l&#432;&#7907;ng b&aacute;n h&agrave;ng ng&agrave;y, ho&#7841;t &#273;&#7897;ng t&#7863;ng
        qu&agrave;, ho&#7841;t &#273;&#7897;ng t&#7863;ng m&#7851;u, ho&#7841;t &#273;&#7897;ng t&#432;&#417;ng
        t&aacute;c v&#7899;i kh&aacute;ch h&agrave;ng (ch&#417;i game, m&#7901;i s&#7843;n ph&#7849;m, gi&#7899;i
        thi&#7879;u ch&#432;&#417;ng tr&igrave;nh&hellip;)</span></li>
    <li class="c10 li-bullet-0"><span class="c4">Ch&uacute;ng t&ocirc;i c&#7847;n nh&#7919;ng th&ocirc;ng tin tr&ecirc;n
        &#273;&#7875; &#273;&#7843;m b&#7843;o ch&#7845;t l&#432;&#7907;ng c&#7911;a t&#7915;ng ca l&agrave;m vi&#7879;c
        v&agrave; qu&#7843;n l&yacute; hi&#7879;u su&#7845;t c&#7911;a to&agrave;n b&#7897; ch&#432;&#417;ng
        tr&igrave;nh.</span></li>
  </ul>
  <p class="c14 c28"><span class="c3"></span></p>
  <ol class="c0 lst-kix_list_1-0" start="2">
    <li class="c1 c24 li-bullet-0"><span class="c6">Which types of personal data do we use/ </span><span
        class="c5">Ch&uacute;ng t&ocirc;i s&#7917; d&#7909;ng lo&#7841;i d&#7919; li&#7879;u c&aacute; nh&acirc;n
        n&agrave;o</span></li>
  </ol>
  <p class="c1"><span class="c2">To register to use the Application, we need you to provide the following information:
      full name, gender, date of birth, phone number, ID number, face photos and personal photo. </span></p>
  <p class="c1"><span class="c2">During usage of the Application, we will collect data on your personal location when
      you are working on the field.</span></p>
  <p class="c1"><span class="c2">When you check in using facial recognition, we collect a vector for the image at check
      in moment &ndash; the vector information is only used to identify your face &ndash; and cannot be used for any
      other purpose.</span></p>
  <p class="c1"><span class="c2">Your information that we collect and process will be completed, relevant and only for
      the specific purposes set out in section 1 of this Notice. Your information needs to be as accurate as possible
      and needs to comply with current laws on personal data protection. </span></p>
  <p class="c1"><span class="c4">&#272;&#7875; &#273;&#259;ng k&yacute; s&#7917; d&#7909;ng &#7912;ng d&#7909;ng,
      ch&uacute;ng t&ocirc;i c&#7847;n b&#7841;n cung c&#7845;p nh&#7919;ng th&ocirc;ng tin sau: h&#7885; v&agrave;
      t&ecirc;n, gi&#7899;i t&iacute;nh, ng&agrave;y sinh, s&#7889; &#273;i&#7879;n tho&#7841;i, s&#7889; CCCD,
      h&igrave;nh &#7843;nh khu&ocirc;n m&#7863;t v&agrave; h&igrave;nh c&aacute; nh&acirc;n. </span></p>
  <p class="c1"><span class="c4">Trong qu&aacute; tr&igrave;nh s&#7917; d&#7909;ng &#7912;ng d&#7909;ng, ch&uacute;ng
      t&ocirc;i s&#7869; thu th&#7853;p d&#7919; li&#7879;u v&#7873; v&#7883; tr&iacute; c&#7911;a b&#7841;n trong
      qu&aacute; tr&igrave;nh l&agrave;m vi&#7879;c ngo&agrave;i th&#7883; tr&#432;&#7901;ng.</span></p>
  <p class="c1"><span class="c4">Khi b&#7841;n checkin b&#7857;ng nh&#7853;n di&#7879;n khu&ocirc;n m&#7863;t,
      ch&uacute;ng t&ocirc;i thu th&#7853;p vector cho h&igrave;nh &#7843;nh l&uacute;c checkin &ndash; th&ocirc;ng tin
      vector ch&#7881; c&oacute; t&aacute;c d&#7909;ng x&aacute;c &#273;&#7883;nh &#273;&uacute;ng khu&ocirc;n m&#7863;t
      c&#7911;a b&#7841;n &ndash; v&agrave; kh&ocirc;ng th&#7875; s&#7917; d&#7909;ng cho b&#7845;t k&#7923; m&#7909;c
      &#273;&iacute;ch n&agrave;o kh&aacute;c.</span></p>
  <p class="c1"><span class="c4">Th&ocirc;ng tin c&#7911;a b&#7841;n m&agrave; ch&uacute;ng t&ocirc;i thu th&#7853;p
      v&agrave; x&#7917; l&yacute; s&#7869; &#273;&#7847;y &#273;&#7911;, c&oacute; li&ecirc;n quan v&agrave; ch&#7881;
      ph&#7909;c v&#7909; cho nh&#7919;ng m&#7909;c &#273;&iacute;ch c&#7909; th&#7875; &#273;&#432;&#7907;c n&ecirc;u
      &#7903; m&#7909;c 1 c&#7911;a Th&ocirc;ng b&aacute;o n&agrave;y. Nh&#7919;ng th&ocirc;ng tin c&#7911;a b&#7841;n
      c&#7847;n ch&iacute;nh x&aacute;c nh&#7845;t c&oacute; th&#7875; v&agrave; c&#7847;n tu&acirc;n th&#7911; theo quy
      &#273;&#7883;nh hi&#7879;n h&agrave;nh v&#7873; b&#7843;o v&#7879; d&#7919; li&#7879;u c&aacute;
      nh&acirc;n.</span></p>
  <ol class="c0 lst-kix_list_1-0" start="3">
    <li class="c1 c29"><span class="c6">Methods of processing your personal data</span><span class="c12">&nbsp;/
      </span><span class="c5">Ph&#432;&#417;ng th&#7913;c x&#7917; l&yacute; d&#7919; li&#7879;u c&aacute; nh&acirc;n
        c&#7911;a b&#7841;n </span></li>
  </ol>
  <p class="c1"><span class="c2">By reading and giving your consent to this Notice, you agree that your information will
      be collected, stored, and used by any method in accordance with the company&rsquo;s policies and/or practices from
      time to time, including without limitation, collection, recording, validation, storage, modification, combination,
      access, retrieval, encryption, copying, transmission, deletion, cancellation, and other related actions. All of
      these methods must be consistent with the purposes set out in section 1 of this Notice. </span></p>
  <p class="c1"><span class="c4">B&#7857;ng c&aacute;ch &#273;&#7885;c v&agrave; cung c&#7845;p s&#7921; &#273;&#7891;ng
      &yacute; c&#7911;a b&#7841;n &#273;&#7889;i v&#7899;i Th&ocirc;ng b&aacute;o n&agrave;y, b&#7841;n &#273;&#7891;ng
      &yacute; r&#7857;ng th&ocirc;ng tin c&#7911;a m&igrave;nh s&#7869; &#273;&#432;&#7907;c thu th&#7853;p, l&#432;u
      tr&#7919;, v&agrave; s&#7917; d&#7909;ng b&#7857;ng b&#7845;t k&#7923; ph&#432;&#417;ng th&#7913;c n&agrave;o theo
      c&aacute;c ch&iacute;nh s&aacute;ch v&agrave;/ho&#7863;c t&igrave;nh h&igrave;nh ho&#7841;t &#273;&#7897;ng
      c&#7911;a C&ocirc;ng ty &#273;&#432;&#7907;c &#273;i&#7873;u ch&#7881;nh theo th&#7901;i gian, bao g&#7891;m
      nh&#432;ng kh&ocirc;ng gi&#7899;i h&#7841;n, thu th&#7853;p, ghi, x&aacute;c nh&#7853;n, l&#432;u tr&#7919;,
      ch&#7881;nh s&#7917;a, k&#7871;t h&#7907;p, truy c&#7853;p, truy xu&#7845;t, thu h&#7891;i, m&atilde; h&oacute;a,
      gi&#7843;i m&atilde;, sao ch&eacute;p, chuy&#7875;n giao, x&oacute;a, h&#7911;y v&agrave; c&aacute;c h&agrave;nh
      &#273;&#7897;ng kh&aacute;c c&oacute; li&ecirc;n quan. T&#7845;t c&#7843; ph&#432;&#417;ng th&#7913;c n&agrave;y
      ph&#7843;i &#273;&#432;&#7907;c ph&ugrave; h&#7907;p v&#7899;i m&#7909;c &#273;&iacute;ch &#273;&#7873; ra
      t&#7841;i m&#7909;c 1 c&#7911;a Th&ocirc;ng b&aacute;o n&agrave;y.</span></p>
  <ol class="c0 lst-kix_list_1-0" start="4">
    <li class="c1 c8 li-bullet-1"><span class="c6">Who has access to your personal data/ </span><span class="c5">Ai
        c&oacute; quy&#7873;n truy c&#7853;p d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a b&#7841;n</span></li>
  </ol>
  <p class="c1"><span class="c2">Only individuals related to the entire project implementation process: the Company, the
      operation agency directly managing you and the DR system team have the right to access to your personal data to
      perform the work under their responsibility and any of the purposes described in this Notice.</span></p>
  <p class="c1"><span class="c4">Ch&#7881; c&oacute; nh&#7919;ng c&aacute; nh&acirc;n li&ecirc;n quan &#273;&#7871;n
      d&#7921; &aacute;n nh&#432; C&ocirc;ng ty, c&ocirc;ng ty qu&#7843;ng c&aacute;o &#273;ang qu&#7843;n l&yacute;
      b&#7841;n v&agrave; nh&oacute;m d&#7921; &aacute;n c&#7911;a DR m&#7899;i c&oacute; quy&#7873;n truy c&#7853;p
      v&agrave;o d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a b&#7841;n &#273;&#7875; th&#7921;c hi&#7879;n
      c&ocirc;ng vi&#7879;c thu&#7897;c tr&aacute;ch nhi&#7879;m c&#7911;a m&igrave;nh v&agrave; th&#7921;c hi&#7879;n
      b&#7845;t k&#7923; m&#7909;c &#273;&iacute;ch n&agrave;o &#273;&#432;&#7907;c m&ocirc; t&#7843; trong Th&ocirc;ng
      b&aacute;o n&agrave;y.</span></p>
  <ol class="c0 lst-kix_list_1-0" start="5">
    <li class="c1 c8 li-bullet-1"><span class="c6">Undesirable consequences and damage that may occur </span><span
        class="c12">/ </span><span class="c5">H&#7853;u qu&#7843;, thi&#7879;t h&#7841;i kh&ocirc;ng mong mu&#7889;n
        c&oacute; kh&#7843; n&#259;ng x&#7843;y ra </span></li>
  </ol>
  <p class="c1"><span class="c2">We consider your personal data as an important asset of the Company and we will ensure
      confidentiality, safety, legal compliance, and limit possible unwanted consequences and damages that might occur
      (including but not limited to: data leakage or inappropriate data processing that harms your legitimate rights and
      interests).</span></p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i xem c&aacute;c d&#7919; li&#7879;u c&aacute; nh&acirc;n
      c&#7911;a c&aacute;c b&#7841;n nh&#432; l&agrave; t&agrave;i s&#7843;n quan tr&#7885;ng c&#7911;a C&ocirc;ng ty
      v&agrave; Ch&uacute;ng t&ocirc;i s&#7869; &#273;&#7843;m b&#7843;o t&iacute;nh b&#7843;o m&#7853;t, an
      to&agrave;n, tu&acirc;n th&#7911; ph&aacute;p lu&#7853;t, v&agrave; h&#7841;n ch&#7871; c&aacute;c h&#7853;u
      qu&#7843;, thi&#7879;t h&#7841;i kh&ocirc;ng mong mu&#7889;n c&oacute; kh&#7843; n&#259;ng x&#7843;y ra (bao
      g&#7891;m nh&#432;ng kh&ocirc;ng gi&#7899;i h&#7841;n: r&ograve; r&#7881; d&#7919; li&#7879;u ho&#7863;c x&#7917;
      l&yacute; d&#7919; li&#7879;u kh&ocirc;ng ph&ugrave; h&#7907;p g&acirc;y t&#7893;n h&#7841;i &#273;&#7871;n
      quy&#7873;n v&agrave; l&#7907;i &iacute;ch h&#7907;p ph&aacute;p c&#7911;a b&#7841;n).</span></p>
  <ol class="c0 lst-kix_list_1-0" start="6">
    <li class="c1 c8 li-bullet-1"><span class="c6">Security/ </span><span class="c5">B&#7843;o m&#7853;t</span></li>
  </ol>
  <p class="c1"><span class="c2">We shall take appropriate commercially reasonable technical, physical, and
      organizational measures to protect personal data from misuse or accidental, unlawful, or unauthorized destruction,
      loss, alteration, disclosure, acquisition, or access.</span></p>
  <p class="c1"><span class="c2">We do not share the personal data of employees collected for this device to any third
      party.</span></p>
  <p class="c1"><span class="c2">We shall notify the Employee of a Data Security Breach within a reasonable period
      following discovery of such breach, unless a law enforcement official or supervisory authority determines that
      notification would impede a (criminal) investigation or cause damage to national security. In this case,
      notification shall be delayed as instructed by such authority. We shall respond promptly to inquiries of Employees
      relating to such Data Security Breaches.</span></p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i s&#7869; th&#7921;c hi&#7879;n c&aacute;c bi&#7879;n ph&aacute;p
      k&#7929; thu&#7853;t, v&#7853;t l&yacute; v&agrave; t&#7893; ch&#7913;c ph&ugrave; h&#7907;p &#273;&#7875;
      b&#7843;o v&#7879; d&#7919; li&#7879;u c&aacute; nh&acirc;n kh&#7887;i b&#7883; l&#7841;m d&#7909;ng ho&#7863;c
      ph&aacute; h&#7911;y, m&#7845;t m&aacute;t, thay &#273;&#7893;i, ti&#7871;t l&#7897;, thu th&#7853;p ho&#7863;c
      truy c&#7853;p v&ocirc; t&igrave;nh, tr&aacute;i ph&aacute;p lu&#7853;t ho&#7863;c tr&aacute;i ph&eacute;p.</span>
  </p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i kh&ocirc;ng chia s&#7867; d&#7919; li&#7879;u c&aacute;
      nh&acirc;n c&#7911;a nh&acirc;n vi&ecirc;n &#273;&#432;&#7907;c thu th&#7853;p cho thi&#7871;t b&#7883; n&agrave;y
      cho b&#7845;t k&#7923; b&ecirc;n th&#7913; ba n&agrave;o.</span></p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i s&#7869; th&ocirc;ng b&aacute;o cho Nh&acirc;n vi&ecirc;n
      v&#7873; Vi ph&#7841;m B&#7843;o m&#7853;t D&#7919; li&#7879;u trong m&#7897;t kho&#7843;ng th&#7901;i gian
      h&#7907;p l&yacute; sau khi ph&aacute;t hi&#7879;n ra vi ph&#7841;m &#273;&oacute;, tr&#7915; khi m&#7897;t quan
      ch&#7913;c th&#7921;c thi ph&aacute;p lu&#7853;t ho&#7863;c c&#417; quan gi&aacute;m s&aacute;t x&aacute;c
      &#273;&#7883;nh r&#7857;ng th&ocirc;ng b&aacute;o &#273;&oacute; s&#7869; c&#7843;n tr&#7903; m&#7897;t cu&#7897;c
      &#273;i&#7873;u tra (h&igrave;nh s&#7921;) ho&#7863;c g&acirc;y thi&#7879;t h&#7841;i cho an ninh qu&#7889;c gia.
      Trong tr&#432;&#7901;ng h&#7907;p n&agrave;y, vi&#7879;c th&ocirc;ng b&aacute;o s&#7869; b&#7883; tr&igrave;
      ho&atilde;n theo h&#432;&#7899;ng d&#7851;n c&#7911;a c&#417; quan &#273;&oacute;. Ch&uacute;ng t&ocirc;i s&#7869;
      ti&#7871;n h&agrave;nh &#273;i&#7873;u tra nhanh ch&oacute;ng v&#7873; Nh&acirc;n vi&ecirc;n li&ecirc;n quan
      &#273;&#7871;n Vi ph&#7841;m B&#7843;o m&#7853;t D&#7919; li&#7879;u &#273;&oacute;.</span></p>
  <ol class="c0 lst-kix_list_1-0" start="7">
    <li class="c1 c8 li-bullet-2"><span class="c6">How long do we retain your personal data/ </span><span
        class="c5">Ch&uacute;ng t&ocirc;i l&#432;u tr&#7919; d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a
        b&#7841;n trong bao l&acirc;u</span></li>
  </ol>
  <p class="c1"><span class="c2">We will retain your personal data for as long as required by law or for as long as
      necessary for any of the purposes listed in this Privacy Notice, or to comply with legal requirements to which We
      are subject, as long as reasonably necessary for archival purposes or as long as consistent with the applicable
      statute of limitations. We will take reasonable steps to destroy or de-identify the personal data we hold if it is
      no longer needed for the purposes set out above or after the expiration of the defined retention term.</span></p>
  <p class="c1"><span class="c2">Time to start processing your personal data: after you agreed with this Notice.</span>
  </p>
  <p class="c1"><span class="c2">Time to end processing your provided personal data: 90 days after completing project
      acceptance procedures and receiving confirmation from parties related to the project you had worked on.</span></p>
  <p class="c1"><span class="c4">Ch&uacute;ng t&ocirc;i s&#7869; l&#432;u tr&#7919; d&#7919; li&#7879;u c&aacute;
      nh&acirc;n c&#7911;a b&#7841;n trong kho&#7843;ng th&#7901;i gian ph&aacute;p lu&#7853;t y&ecirc;u c&#7847;u
      ho&#7863;c kho&#7843;ng th&#7901;i gian c&#7847;n thi&#7871;t cho b&#7845;t k&#7923; m&#7909;c &#273;&iacute;ch
      n&agrave;o &#273;&#432;&#7907;c li&#7879;t k&ecirc; trong Th&ocirc;ng b&aacute;o n&agrave;y, ho&#7863;c
      &#273;&#7875; tu&acirc;n th&#7911; c&aacute;c y&ecirc;u c&#7847;u ph&aacute;p l&yacute; m&agrave; Ch&uacute;ng
      t&ocirc;i ph&#7843;i tu&acirc;n theo, mi&#7877;n l&agrave; c&#7847;n thi&#7871;t m&#7897;t c&aacute;ch h&#7907;p
      l&yacute; cho m&#7909;c &#273;&iacute;ch l&#432;u tr&#7919; ho&#7863;c mi&#7877;n l&agrave; ph&ugrave; h&#7907;p
      v&#7899;i th&#7901;i hi&#7879;u &aacute;p d&#7909;ng. Ch&uacute;ng t&ocirc;i s&#7869; th&#7921;c hi&#7879;n
      c&aacute;c b&#432;&#7899;c h&#7907;p l&yacute; &#273;&#7875; h&#7911;y ho&#7863;c h&#7911;y nh&#7853;n d&#7841;ng
      d&#7919; li&#7879;u c&aacute; nh&acirc;n m&agrave; Ch&uacute;ng t&ocirc;i l&#432;u tr&#7919; n&#7871;u d&#7919;
      li&#7879;u &#273;&oacute; kh&ocirc;ng c&ograve;n c&#7847;n thi&#7871;t cho c&aacute;c m&#7909;c &#273;&iacute;ch
      n&ecirc;u tr&ecirc;n ho&#7863;c sau khi h&#7871;t th&#7901;i h&#7841;n l&#432;u tr&#7919; &#273;&atilde;
      x&aacute;c &#273;&#7883;nh.</span></p>
  <p class="c1"><span class="c4">Th&#417;&#768;i gian chu&#769;ng t&ocirc;i b&#259;&#769;t &#273;&acirc;&#768;u
      x&#432;&#777; ly&#769; nh&#432;&#771;ng d&#432;&#771; li&ecirc;&#803;u ca&#769; nh&acirc;n: sau khi chu&#777;
      th&ecirc;&#777; d&#432;&#771; li&ecirc;&#803;u &#273;&ocirc;&#768;ng y&#769; v&#417;&#769;i Th&ocirc;ng b&aacute;o
      na&#768;y. </span></p>
  <p class="c1"><span class="c4">Th&#417;&#768;i gian k&ecirc;&#769;t thu&#769;c vi&ecirc;&#803;c x&#432;&#777; ly&#769;
      nh&#432;&#771;ng d&#432;&#771; li&ecirc;&#803;u ca&#769; nh&acirc;n: 90 ng&agrave;y sau khi ho&agrave;n t&#7845;t
      c&aacute;c th&#7911; t&#7909;c nghi&#7879;m thu d&#7921; &aacute;n v&agrave; nh&#7853;n &#273;&#432;&#7907;c
      x&aacute;c nh&#7853;n t&#7915; c&aacute;c b&ecirc;n li&ecirc;n quan &#273;&#7871;n d&#7921; &aacute;n b&#7841;n
      l&agrave;m vi&#7879;c.</span></p>
  <p class="c1"><span class="c6">8. </span><span class="c12">Your rights and obligations / </span><span
      class="c5">Quy&#7873;n v&agrave; ngh&#297;a v&#7909; c&#7911;a b&#7841;n </span></p>
  <p class="c1" id="h.gjdgxs"><span class="c2">You have rights to your personal data, which include: the right to
      consent, the right to withdraw consent, the right to deletion, the right to restrict data processing and other
      rights as prescribed by applicable law on personal data protection.</span></p>
  <p class="c1"><span class="c2">You have an obligation to: protect your personal data; respect and protect the personal
      data of others; Provide complete and accurate personal data when giving consent to process personal data; and
      other obligations according to current laws on personal data protection.</span></p>
  <p class="c1"><span class="c4">B&#7841;n c&oacute; quy&#7873;n &#273;&#7889;i v&#7899;i d&#7919; li&#7879;u c&aacute;
      nh&acirc;n c&#7911;a m&igrave;nh, trong &#273;&oacute; bao g&#7891;m: quy&#7873;n &#273;&#7891;ng &yacute;,
      quy&#7873;n r&uacute;t l&#7841;i s&#7921; &#273;&#7891;ng &yacute;, quy&#7873;n x&oacute;a d&#7919; li&#7879;u,
      quy&#7873;n h&#7841;n ch&#7871; x&#7917; l&yacute; d&#7919; li&#7879;u v&agrave; c&aacute;c quy&#7873;n
      kh&aacute;c theo quy &#273;&#7883;nh c&#7911;a ph&aacute;p lu&#7853;t hi&#7879;n h&agrave;nh v&#7873; b&#7843;o
      v&#7879; d&#7919; li&#7879;u c&aacute; nh&acirc;n.</span></p>
  <p class="c1"><span class="c4">B&#7841;n c&oacute; ngh&#297;a v&#7909;: t&#7921; b&#7843;o v&#7879; d&#7919;
      li&#7879;u c&aacute; nh&acirc;n c&#7911;a m&igrave;nh; t&ocirc;n tr&#7885;ng, b&#7843;o v&#7879; d&#7919;
      li&#7879;u c&aacute; nh&acirc;n c&#7911;a ng&#432;&#7901;i kh&aacute;c; cung c&#7845;p &#273;&#7847;y
      &#273;&#7911;, ch&iacute;nh x&aacute;c d&#7919; li&#7879;u c&aacute; nh&acirc;n khi &#273;&#7891;ng &yacute; cho
      ph&eacute;p x&#7917; l&yacute; d&#7919; li&#7879;u c&aacute; nh&acirc;n; v&agrave; c&aacute;c ngh&#297;a v&#7909;
      kh&aacute;c theo quy &#273;&#7883;nh c&#7911;a ph&aacute;p lu&#7853;t hi&#7879;n h&agrave;nh v&#7873; b&#7843;o
      v&#7879; d&#7919; li&#7879;u c&aacute; nh&acirc;n.</span></p>
  <p class="c1"><span class="c6">9. Access and correction requests, questions and complaints/ </span><span
      class="c5">Truy c&#7853;p v&agrave; y&ecirc;u c&#7847;u ch&#7881;nh s&#7917;a, th&#7855;c m&#7855;c v&agrave;
      khi&#7871;u n&#7841;i</span></p>
  <p class="c1"><span class="c2">Please keep your personal data up to date and inform us of any significant
      changes.</span></p>
  <p class="c1"><span class="c2">You have the right to request the provision of aggregate data about your personal data
      that has been processed by us. You have the right to request your data to be corrected, deleted, or restricted (if
      necessary), all subject to applicable personal data protection regulations and other relevant policies and
      guidelines of the Company.</span></p>
  <p class="c1"><span class="c2">You also have a right, in certain circumstances, to require us to stop processing your
      personal data. However, we will not be able to process this request if we have a legitimate ground for continuing
      to process your personal data. When you have provided consent to our use of your personal data, you have the right
      to withdraw your consent without this effecting the lawfulness of our use of this data before your
      withdrawal.</span></p>
  <p class="c1"><span>In case the staff wants to fulfil the above requests or data breach report, please contact us via
    </span><span class="c21"><a class="c9" href="mailto:binh.nt@drdigital.vn">binh.nt@drdigital.vn</a></span><span
      class="c17 c18">&nbsp;</span><span>or </span><span class="c21"><a class="c9"
        href="mailto:privacyvn@heineken.com">privacyvn@heineken.com</a></span><span class="c2">&nbsp;for further
      support.</span></p>
  <p class="c1"><span class="c4">Vui l&ograve;ng c&#7853;p nh&#7853;t d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a
      b&#7841;n v&agrave; th&ocirc;ng b&aacute;o cho Ch&uacute;ng t&ocirc;i v&#7873; b&#7845;t k&#7923; thay
      &#273;&#7893;i quan tr&#7885;ng n&agrave;o.</span></p>
  <p class="c1"><span class="c4">B&#7841;n c&oacute; quy&#7873;n y&ecirc;u c&#7847;u cung c&#7845;p d&#7919; li&#7879;u
      t&#7893;ng h&#7907;p v&#7873; d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a b&#7841;n &#273;&atilde;
      &#273;&#432;&#7907;c Ch&uacute;ng t&ocirc;i x&#7917; l&yacute;. B&#7841;n c&oacute; quy&#7873;n y&ecirc;u
      c&#7847;u ch&#7881;nh s&#7917;a, x&oacute;a ho&#7863;c h&#7841;n ch&#7871; d&#7919; li&#7879;u c&#7911;a
      m&igrave;nh (n&#7871;u c&#7847;n thi&#7871;t) theo qui &#273;&#7883;nh hi&#7879;n h&agrave;nh v&#7873; b&#7843;o
      v&#7879; d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#361;ng nh&#432; c&aacute;c qui &#273;&#7883;nh v&agrave;
      h&#432;&#7899;ng d&#7851;n li&ecirc;n quan kh&aacute;c c&#7911;a C&ocirc;ng ty.</span></p>
  <p class="c1"><span class="c4">Trong m&#7897;t s&#7889; tr&#432;&#7901;ng h&#7907;p nh&#7845;t &#273;&#7883;nh,
      b&#7841;n c&#361;ng c&oacute; quy&#7873;n y&ecirc;u c&#7847;u Ch&uacute;ng t&ocirc;i ng&#7915;ng x&#7917;
      l&yacute; d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a b&#7841;n. Tuy nhi&ecirc;n, Ch&uacute;ng t&ocirc;i
      s&#7869; kh&ocirc;ng th&#7875; gi&#7843;i quy&#7871;t y&ecirc;u c&#7847;u n&agrave;y n&#7871;u Ch&uacute;ng
      t&ocirc;i c&oacute; c&#417; s&#7903; h&#7907;p ph&aacute;p &#273;&#7875; ti&#7871;p t&#7909;c x&#7917; l&yacute;
      d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a b&#7841;n. Khi b&#7841;n &#273;&atilde; &#273;&#7891;ng
      &yacute; cho Ch&uacute;ng t&ocirc;i s&#7917; d&#7909;ng d&#7919; li&#7879;u c&aacute; nh&acirc;n c&#7911;a
      b&#7841;n, b&#7841;n c&oacute; quy&#7873;n r&uacute;t l&#7841;i s&#7921; &#273;&#7891;ng &yacute; c&#7911;a
      m&igrave;nh n&#7871;u kh&ocirc;ng &#7843;nh h&#432;&#7903;ng &#273;&#7871;n t&iacute;nh h&#7907;p ph&aacute;p
      c&#7911;a vi&#7879;c Ch&uacute;ng t&ocirc;i s&#7917; d&#7909;ng d&#7919; li&#7879;u n&agrave;y tr&#432;&#7899;c
      khi b&#7841;n r&uacute;t l&#7841;i.</span></p>
  <p class="c1"><span class="c18 c17">Trong tr&#432;&#7901;ng h&#7907;p nh&acirc;n vi&ecirc;n mu&#7889;n th&#7921;c
      hi&#7879;n c&aacute;c y&ecirc;u c&#7847;u tr&ecirc;n ho&#7863;c b&aacute;o c&aacute;o vi ph&#7841;m d&#7919;
      li&#7879;u, vui l&ograve;ng li&ecirc;n h&#7879; v&#7899;i Ch&uacute;ng t&ocirc;i qua &#273;&#7883;a ch&#7881;
    </span><span class="c21 c18"><a class="c9" href="mailto:binh.nt@drdigital.vn">binh.nt@drdigital.vn</a></span><span
      class="c18 c17">&nbsp;</span><span class="c17">ho&#7863;c </span><span class="c17 c26"><a class="c9"
        href="mailto:privacyvn@heineken.com">privacyvn@heineken.com</a></span><span class="c17">&nbsp;</span><span
      class="c4">&#273;&#7875; &#273;&#432;&#7907;c h&#7895; tr&#7907;. </span></p>
  <p class="c1"><span class="c6">10. Hi&#7879;u l&#7921;c c&#7911;a Th&ocirc;ng b&aacute;o</span><span
      class="c12">&nbsp;/ </span><span class="c5">The Notice Validity</span></p>
  <p class="c1"><span class="c2">This Notice is made in Vietnamese and English version. In case where there is a
      discrepancy in the meaning between Vietnamese and English, the Vietnamese version shall prevail.</span></p>
  <p class="c1"><span class="c2">This Notice will be effective from 01 July 2023. The Notice may be adjusted depending
      on the operating situation and the update of the Notice will be sent to you to read and confirm your
      consent.</span></p>
  <p class="c1"><span class="c4">Th&ocirc;ng b&aacute;o n&agrave;y &#273;&#432;&#7907;c l&agrave;m b&#7857;ng hai
      ng&ocirc;n ng&#7919; Ti&#7871;ng Vi&#7879;t v&agrave; Ti&#7871;ng Anh. Trong tr&#432;&#7901;ng h&#7907;p c&oacute;
      s&#7921; kh&aacute;c bi&#7879;t v&#7873; ngh&#297;a gi&#7919;a b&#7843;n Ti&#7871;ng Vi&#7879;t v&agrave;
      Ti&#7871;ng Anh th&igrave; b&#7843;n Ti&#7871;ng Vi&#7879;t s&#7869; c&oacute; gi&aacute; tr&#7883; &aacute;p
      d&#7909;ng.</span></p>
  <p class="c1"><span class="c4">Th&ocirc;ng b&aacute;o n&agrave;y s&#7869; c&oacute; hi&#7879;u l&#7921;c t&#7915;
      ng&agrave;y 01 th&aacute;ng 07 n&#259;m 2023. Th&ocirc;ng b&aacute;o c&oacute; th&#7875; &#273;&#432;&#7907;c
      &#273;i&#7873;u ch&#7881;nh t&ugrave;y theo t&igrave;nh h&igrave;nh ho&#7841;t &#273;&#7897;ng v&agrave;
      vi&#7879;c c&#7853;p nh&#7853;t Th&ocirc;ng b&aacute;o s&#7869; &#273;&#432;&#7907;c g&#7917;i cho b&#7841;n
      &#273;&#7885;c v&agrave; x&aacute;c nh&#7853;n &#273;&#7891;ng &yacute;.</span></p>
  <p class="c1 c14"><span class="c2"></span></p>
  <div>
    <p class="c16"><span class="c6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
  </div>
`;

export const placeHolder = '';
